<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Operator General  Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-id-badge text-22px text-oPurple"></i>
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorGeneralSettings.title'
            )
          }}</span>
        </div>
        <p class="break-words title-description mt-1">
          {{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorGeneralSettings.description'
            )
          }}
        </p>
      </div>
      <div :key="`operatorGeneral_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in getOperatorGeneralSettings">
          <template
            v-if="item.variable !== 'operator_hardware_commands_allowed'"
          >
            <SmartToggleInput
              v-if="item.type === 'toggle'"
              :key="itemIndex"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="general[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`operatorGeneral_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="general[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>

          <SmartToggleInput
            v-if="item.variable === 'operator_hardware_commands_allowed'"
            :key="itemIndex"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="general[`${item.variable}`]"
            @save="onSave('organization', `${item.variable}`, $event)"
          />
        </template>
      </div>

      <!-- Operator Task Wage Settings -->
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-money-bill text-22px text-oPurple"></i>
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorTaskWage.title'
            )
          }}</span>
        </div>
        <p class="break-words title-description mt-1">
          {{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorTaskWage.description'
            )
          }}
        </p>
      </div>
      <div :key="`operatorWage_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in operatorWageSettings">
          <SmartAppInput
            v-if="itemIndex <= 1"
            :key="itemIndex"
            :rowId="`operatorWage_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="wage.charging[`${item.variable}`]"
            @save="onSave('charging', `${item.variable}`, $event)"
          />
          <SmartAppInput
            v-else
            :key="itemIndex"
            :rowId="`operatorWage_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="wage.rebalancing[`${item.variable}`]"
            @save="onSave('rebalancing', `${item.variable}`, $event)"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import { EventBus } from '@/utils'
import { OperatorSettingsConfig } from '@/config/SettingsConfig'
import { useEndpoints } from '@/composables'

export default {
  name: 'OperatorSettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      general: {
        drop_off_photo_required: true,
        check_inside_drop_off_area: true,
        is_reservation_required: true,
        release_spot_reservation_time_in_min: 0,
        operator_hardware_commands_allowed: true,
      },

      wage: {
        charging: {
          rate: '',
          point: '',
        },
        rebalancing: {
          rate: '',
          point: '',
        },
      },
      operatorGeneralSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.requireDropOffPhoto.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.requireDropOffPhoto.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'drop_off_photo_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.validateInsideDropOffArea.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.validateInsideDropOffArea.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'check_inside_drop_off_area',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.reservationRequirement.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.reservationRequirement.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_reservation_required',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.releaseSpotReservationTimeoutMinutes.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.releaseSpotReservationTimeoutMinutes.description'
          ),
          variable: 'release_spot_reservation_time_in_min',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.allowOperatorHardwareCommands.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.allowOperatorHardwareCommands.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'operator_hardware_commands_allowed',
          isEdit: false,
        },
      ],
      operatorWageSettings: [
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForChargingTask.title'
          ),

          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForChargingTask.description'
          ),
          variable: 'rate',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForChargingTask.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForChargingTask.description'
          ),
          variable: 'point',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForRebalancingTask.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForRebalancingTask.description'
          ),
          variable: 'rate',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForRebalancingTask.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForRebalancingTask.description'
          ),
          variable: 'point',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getOperatorGeneralSettings() {
      const allOperatorGeneralSettings = this.operatorGeneralSettings
      if (this.general.is_reservation_required === false) {
        const removeSettingsKey = ['release_spot_reservation_time_in_min']
        const filteredSettings = allOperatorGeneralSettings.filter(
          (item) => !removeSettingsKey.includes(item.variable)
        )
        return filteredSettings
      } else {
        return this.operatorGeneralSettings
      }
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'operatorGeneral') {
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'operatorWage') {
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
      },
    },
  },
  async created() {
    const reqCharging = this.$http.get(
      OperatorSettingsConfig.api.charging.index(this.orgId)
    )
    const reqRebalancing = this.$http.get(
      OperatorSettingsConfig.api.rebalancing.index(this.orgId)
    )
    const reqGeneral = this.$http.get(
      OperatorSettingsConfig.api.general.index(this.orgId)
    )

    const orgReq = this.$http.get(useEndpoints.organization.details(this.orgId))
    this.$http
      .all([reqCharging, reqRebalancing, reqGeneral, orgReq])
      .then(
        this.$http.spread((...responses) => {
          console.log('settignsData', responses)
          this.general.drop_off_photo_required =
            responses[2].data.drop_off_photo_required
          this.general.is_reservation_required =
            responses[2].data.is_reservation_required
          this.general.check_inside_drop_off_area =
            responses[2].data.check_inside_drop_off_area
          this.general.release_spot_reservation_time_in_min =
            responses[2].data.release_spot_reservation_time_in_min

          this.general.operator_hardware_commands_allowed =
            responses[3].data.operator_hardware_commands_allowed

          this.wage.charging.rate = responses[0].data.rate
          this.wage.charging.point = responses[0].data.point

          this.wage.rebalancing.rate = responses[1].data.rate
          this.wage.rebalancing.point = responses[1].data.point

          this.isLoaded = true
        })
      )
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'operatorGeneral') {
        this.operatorGeneralSettings = this.operatorGeneralSettings.map(
          (item, itemIndex) => {
            if (id === `operatorGeneral_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'operatorGeneral'
      }
      if (idPrefix === 'operatorWage') {
        this.operatorWageSettings = this.operatorWageSettings.map(
          (item, itemIndex) => {
            if (id === `operatorWage_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'operatorWage'
      }
    })
  },
  methods: {
    async onSave(group, key, val) {
      let toastTitle, toastText

      if (key === 'drop_off_photo_required') {
        toastTitle = `Drop-off Photo Required`
        toastText = `Drop-off Photo Required has been updated`
      }
      if (key === 'check_inside_drop_off_area') {
        toastTitle = `Check Inside Drop-off Area`
        toastText = `Check Inside Drop-off Area has been updated`
      }
      if (key === 'is_reservation_required') {
        toastTitle = `Reservation Required`
        toastText = `Reservation Required has been updated`
      }
      if (key === 'release_spot_reservation_time_in_min') {
        toastTitle = `Release Spot Reservation Timeout (Minutes)`
        toastText = `Release Spot Reservation Timeout (Minutes) has been updated`
      }
      if (group === 'charging' && key === 'rate') {
        toastTitle = `Earning Rate (Charging)`
        toastText = `Earning Rate (Charging) has been updated`
      }
      if (group === 'charging' && key === 'point') {
        toastTitle = `Point (Charging)`
        toastText = `Point (Charging) has been updated`
      }
      if (group === 'rebalancing' && key === 'rate') {
        toastTitle = `Earning Rate (Rebalance)`
        toastText = `Earning Rate (Rebalance) has been updated`
      }
      if (group === 'rebalancing' && key === 'point') {
        toastTitle = `Point (Rebalance)`
        toastText = `Point (Rebalance) has been updated`
      }

      if (
        group === 'organization' &&
        key === 'operator_hardware_commands_allowed'
      ) {
        toastTitle = `Operator hardware commands allowed`
        toastText = `Operator hardware commands allowed has been updated`
      }

      let data = new FormData()
      data.append(key, val)
      let reqLink = null
      if (group === 'general') {
        reqLink = OperatorSettingsConfig.api.general.index(this.orgId)
      }
      if (group === 'charging') {
        reqLink = OperatorSettingsConfig.api.charging.index(this.orgId)
      }
      if (group === 'rebalancing') {
        reqLink = OperatorSettingsConfig.api.rebalancing.index(this.orgId)
      }
      if (group === 'organization') {
        reqLink = useEndpoints.organization.details(this.orgId)
      }
      await this.$http
        .patch(reqLink, data)
        .then(() => {
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
